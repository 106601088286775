<template>
    <div>
        <transition name="vload">
            <div v-show="isLoading" class="Loader">
                <div class="sk-cube-grid">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                    <div class="sk-cube sk-cube3"></div>
                    <div class="sk-cube sk-cube4"></div>
                    <div class="sk-cube sk-cube5"></div>
                    <div class="sk-cube sk-cube6"></div>
                    <div class="sk-cube sk-cube7"></div>
                    <div class="sk-cube sk-cube8"></div>
                    <div class="sk-cube sk-cube9"></div>
                </div>
                <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
            </div>
        </transition>
        <div id="main-wrapper">

            <Header></Header>
            <div class="header-margin"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title page-title-large">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="ipt-title">【国内最大級】1400社超のSES企業・フリーランスエージェントリスト</h1>
                            <div class="ipt-date">
                                <p class="ipt-text">公開日：2021年6月10日</p>
                                <p class="ipt-text">更新日：{{updateDate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->
            <section class="padding0">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="bredcrumb_wrap">
                                <nav>
                                    <ol class="breadcrumb bredcrumb_companylist">
                                        <li class="breadcrumb-item bredcrumb_item_companylist">
                                            <router-link to="/">ホーム</router-link>
                                        </li>
                                        <li class="breadcrumb-item bredcrumb_item_companylist" aria-current="page">
                                            SES企業・フリーランスエージェントリスト
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ Text Section Start ================================== -->
            <section class="fixed paddingTop0  comapnylist-explanation">
                <div class="comapnylist-left-bg"/>
                <div class="comapnylist-right-bg"/>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="companylist-frame">
                                <span>ABOUT</span>
                                <h2>SES企業・フリーランスエージェントリストとは？</h2>
                                <p>SES
                                    MEDIAの『SES企業・フリーランスエージェントリスト』は、首都圏を中心とした国内最大規模約1,400社のSES企業やフリーランスエージェントの情報を一覧で確認することができるページです。
                                    企業名・サイトURL・従業員数・派遣免許取得有無などの基本情報をはじめ、SESの営業に役立つ「パートナー企業の募集ステータス」なども掲載。また、2024年1月より登録中企業の求人情報を確認できるようになりました。 </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style="padding-top: 100px; padding-bottom: 25px;">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="companylist-frame how-to-use">
                                <div class="how-to-use-title">
                                    <div class="how-to-use-bar"/>
                                    <span>HOW TO</span>
                                    <h2>活用方法</h2>
                                </div>
                                <div class="how-to-use-itemframe">
                                    <img class="how-to-num" src="assets/img/01.svg" alt="01">
                                    <div class="how-to-item">
                                        <h3>ビジネスパートナー・協業先を探す</h3>
                                        <p style="padding-top: 0; text-align: left;">
                                            会社名や所在地情報はもちろん、「案件に強い」「直フリーランスの扱いあり」など、SES企業の特徴まで掲載しているので、自社のニーズにマッチするビジネスパートナーや協業先を探すことができます。</p>
                                    </div>
                                </div>
                                <div>
                                    <div class="how-to-use-itemframe">
                                        <img class="how-to-num" src="assets/img/02.svg" alt="02">
                                        <div class="how-to-item">
                                            <h3>ビジネスパートナー・協業先からの問い合わせを増やす</h3>
                                            <p style="padding-top: 0; text-align: left;">
                                                リストに企業情報を掲載することで、ビジネスパートナー・協業先からの問い合わせを増やすことができます。掲載は完全無料、今後はSES企業様向けに営業に役立つ様々な機能を追加予定ですので、この機会に是非、アカウントを作成し企業情報の登録をお試しください。</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="how-to-use-itemframe">
                                        <img class="how-to-num" src="assets/img/03.svg" alt="03">
                                        <div class="how-to-item">
                                            <h3>求人を掲載し、採用に役立てる！</h3>
                                            <p style="padding-top: 0; text-align: left;">
                                                月間2,000名以上のSES関係者が訪れる本リストに、SES業界特化の求人を掲載することが可能。また、気になった求人には応募することもできます。気になった求人があったら気軽に応募してみてください。</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="button-companylist">
                <div style="height: 73.5px; width: 800px; display: contents;">
                    <a href="https://sesmedia.jp/register">リストを活用して、SES事業を加速させる！</a>
                </div>
            </div>
            <section style="padding: 0">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="companylist-frame attention-frame">
                                <div class="attention-title">
                                    <div class="circle-point"/>
                                    <h2>ご利用の際の注意事項</h2>
                                </div>
                                <div class="attention-content">
                                    <ul class="">
                                        <li class="text-light precautionary-text">・掲載している情報が正確ではない可能性があります。</li>
                                        <br>
                                        <li class="text-light precautionary-text">・現在、存在していない企業を掲載している可能性があります。</li>
                                        <br>
                                        <li class="text-light precautionary-text">・本リストを使用しての問題やトラブルの責任については一切負いません。
                                        </li>
                                        <br>
                                        <li class="text-light precautionary-text">
                                            ・すべてのSES企業様・フリーランスエージェント様を掲載できておりません。
                                        </li>
                                        <br>
                                        <li class="text-light precautionary-text">
                                            ・現在、SES事業・フリーランスエージェント事業を行っていない企業を掲載している可能性があります。
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- ============================ Text Section End ================================== -->
            <!-- ============================ Main Section Start ================================== -->
            <section class="paddingTop0">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <!-- Ads Area PC-->
                            <!--<div class="line-qr-area">
                                <img class="qr-img" src="assets/img/qr.jpg" class="logo" alt="">
                                <h6 class="mt-100">SES営業のための雑談オープンチャット！</h6>
                            </div> -->

                            <div class="simple-sidebar sm-sidebar simple-sidebar-sticky">
                                <div class="search-sidebar_header">
                                    <h4 class="ssh_heading">条件検索・絞り込み</h4>
                                    <a class="clear_all" @click="resetFilter">リセット</a><a href="#search_open"
                                                                                         data-toggle="collapse"
                                                                                         aria-expanded="false"
                                                                                         role="button"
                                                                                         class="collapsed _filter-ico"><i
                                        class="fa fa-sliders"></i></a>
                                </div>
                                <!-- Find New Property -->
                                <div class="sidebar-widgets collapse miz_show" id="search_open"
                                     data-parent="#search_open">
                                    <div class="search-inner p-0">

                                        <div class="filter-search-box pb-0">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="会社名"
                                                       v-model="filter.name">
                                            </div>
                                        </div>
                                        <!-- Search Area -->
                                        <div class="filter_wraps">
                                            <!-- Status Search -->
                                            <div class="single_search_boxed">
                                                <div class="widget-boxed-header">
                                                    <h4>
                                                        <a href="#status" data-toggle="collapse" aria-expanded="false"
                                                           role="button" class="collapsed">パートナー募集状況</a>
                                                    </h4>
                                                </div>
                                                <div class="widget-boxed-body collapse" id="status"
                                                     data-parent="#status">
                                                    <div class="side-list no-border">
                                                        <div class="single_filter_card">
                                                            <div class="card-body pt-0">
                                                                <div class="inner_widget_link">
                                                                    <ul class="no-ul-list filter-list">
                                                                        <li>
                                                                            <input id="a0" class="checkbox-custom"
                                                                                   type="checkbox" value="1"
                                                                                   v-model="filter.recruitmentStatus">
                                                                            <label for="a0"
                                                                                   class="checkbox-custom-label">パートナー企業積極募集中</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="a1" class="checkbox-custom"
                                                                                   type="checkbox" value="2"
                                                                                   v-model="filter.recruitmentStatus">
                                                                            <label for="a1"
                                                                                   class="checkbox-custom-label">案件に強いパートナー企業募集中</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="a2" class="checkbox-custom"
                                                                                   type="checkbox" value="3"
                                                                                   v-model="filter.recruitmentStatus">
                                                                            <label for="a2"
                                                                                   class="checkbox-custom-label">要員に強いパートナー企業募集中</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="a3" class="checkbox-custom"
                                                                                   type="checkbox" value="4"
                                                                                   v-model="filter.recruitmentStatus">
                                                                            <label for="a3"
                                                                                   class="checkbox-custom-label">パートナー企業募集停止中</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Feature Search -->
                                            <div class="single_search_boxed">
                                                <div class="widget-boxed-header">
                                                    <h4>
                                                        <a href="#feature" data-toggle="collapse" aria-expanded="false"
                                                           role="button" class="collapsed">特徴</a>
                                                    </h4>
                                                </div>
                                                <div class="widget-boxed-body collapse" id="feature"
                                                     data-parent="#feature">
                                                    <div class="side-list no-border">
                                                        <div class="single_filter_card">
                                                            <div class="card-body pt-0">
                                                                <div class="inner_widget_link">
                                                                    <ul class="no-ul-list filter-list">
                                                                        <li>
                                                                            <input id="b0" class="checkbox-custom"
                                                                                   type="checkbox" value="0"
                                                                                   v-model="filter.features">
                                                                            <label for="b0"
                                                                                   class="checkbox-custom-label">エンド直案件あり</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="b1" class="checkbox-custom"
                                                                                   type="checkbox" value="1"
                                                                                   v-model="filter.features">
                                                                            <label for="b1"
                                                                                   class="checkbox-custom-label">元請け直案件あり</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="b2" class="checkbox-custom"
                                                                                   type="checkbox" value="2"
                                                                                   v-model="filter.features">
                                                                            <label for="b2"
                                                                                   class="checkbox-custom-label">ロースキル案件あり</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="b3" class="checkbox-custom"
                                                                                   type="checkbox" value="3"
                                                                                   v-model="filter.features">
                                                                            <label for="b3"
                                                                                   class="checkbox-custom-label">自社内・グループ会社内案件あり</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="b4" class="checkbox-custom"
                                                                                   type="checkbox" value="4"
                                                                                   v-model="filter.features">
                                                                            <label for="b4"
                                                                                   class="checkbox-custom-label">社員・プロパー営業あり</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="b5" class="checkbox-custom"
                                                                                   type="checkbox" value="5"
                                                                                   v-model="filter.features">
                                                                            <label for="b5"
                                                                                   class="checkbox-custom-label">直フリーランスの扱いあり</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="b6" class="checkbox-custom"
                                                                                   type="checkbox" value="6"
                                                                                   v-model="filter.features">
                                                                            <label for="b6"
                                                                                   class="checkbox-custom-label">業務系に強い</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="b7" class="checkbox-custom"
                                                                                   type="checkbox" value="7"
                                                                                   v-model="filter.features">
                                                                            <label for="b7"
                                                                                   class="checkbox-custom-label">Web系に強い</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="b8" class="checkbox-custom"
                                                                                   type="checkbox" value="8"
                                                                                   v-model="filter.features">
                                                                            <label for="b8"
                                                                                   class="checkbox-custom-label">ゲーム系に強い</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Area Search-->
                                            <div class="single_search_boxed">
                                                <div class="widget-boxed-header">
                                                    <h4>
                                                        <a href="#area" data-toggle="collapse" aria-expanded="false"
                                                           role="button" class="collapsed">地域・エリア</a>
                                                    </h4>
                                                </div>
                                                <div class="widget-boxed-body collapse" id="area" data-parent="#area">
                                                    <div class="side-list no-border">
                                                        <div class="single_filter_card">
                                                            <div class="card-body pt-0">
                                                                <div class="inner_widget_link">
                                                                    <ul class="no-ul-list filter-list">
                                                                        <li>
                                                                            <input id="c0" class="checkbox-custom"
                                                                                   type="checkbox" value="0"
                                                                                   v-model="filter.area">
                                                                            <label for="c0"
                                                                                   class="checkbox-custom-label">関東</label>
                                                                            <ul class="no-ul-list filter-list">
                                                                                <li>
                                                                                    <input id="c0-0"
                                                                                           class="checkbox-custom"
                                                                                           type="checkbox" value="0"
                                                                                           v-model="filter.subArea">
                                                                                    <label for="c0-0"
                                                                                           class="checkbox-custom-label">東京23区</label>
                                                                                </li>
                                                                                <li>
                                                                                    <input id="c0-1"
                                                                                           class="checkbox-custom"
                                                                                           type="checkbox" value="1"
                                                                                           v-model="filter.subArea">
                                                                                    <label for="c0-1"
                                                                                           class="checkbox-custom-label">東京23区外</label>
                                                                                </li>
                                                                                <li>
                                                                                    <input id="c0-2"
                                                                                           class="checkbox-custom"
                                                                                           type="checkbox" value="2"
                                                                                           v-model="filter.subArea">
                                                                                    <label for="c0-2"
                                                                                           class="checkbox-custom-label">神奈川県</label>
                                                                                </li>
                                                                                <li>
                                                                                    <input id="c0-3"
                                                                                           class="checkbox-custom"
                                                                                           type="checkbox" value="3"
                                                                                           v-model="filter.subArea">
                                                                                    <label for="c0-3"
                                                                                           class="checkbox-custom-label">千葉県</label>
                                                                                </li>
                                                                                <li>
                                                                                    <input id="c0-4"
                                                                                           class="checkbox-custom"
                                                                                           type="checkbox" value="4"
                                                                                           v-model="filter.subArea">
                                                                                    <label for="c0-4"
                                                                                           class="checkbox-custom-label">埼玉県</label>
                                                                                </li>
                                                                                <li>
                                                                                    <input id="c0-5"
                                                                                           class="checkbox-custom"
                                                                                           type="checkbox" value="5"
                                                                                           v-model="filter.subArea">
                                                                                    <label for="c0-5"
                                                                                           class="checkbox-custom-label">茨城県</label>
                                                                                </li>
                                                                                <li>
                                                                                    <input id="c0-6"
                                                                                           class="checkbox-custom"
                                                                                           type="checkbox" value="6"
                                                                                           v-model="filter.subArea">
                                                                                    <label for="c0-6"
                                                                                           class="checkbox-custom-label">群馬県</label>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>
                                                                            <input id="c1" class="checkbox-custom"
                                                                                   type="checkbox" value="1"
                                                                                   v-model="filter.area">
                                                                            <label for="c1"
                                                                                   class="checkbox-custom-label">東海</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="c2" class="checkbox-custom"
                                                                                   type="checkbox" value="2"
                                                                                   v-model="filter.area">
                                                                            <label for="c2"
                                                                                   class="checkbox-custom-label">関西</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="c3" class="checkbox-custom"
                                                                                   type="checkbox" value="3"
                                                                                   v-model="filter.area">
                                                                            <label for="c3"
                                                                                   class="checkbox-custom-label">九州</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="c4" class="checkbox-custom"
                                                                                   type="checkbox" value="4"
                                                                                   v-model="filter.area">
                                                                            <label for="c4"
                                                                                   class="checkbox-custom-label">その他</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Establishment time Search -->
                                            <div class="single_search_boxed">
                                                <div class="widget-boxed-header">
                                                    <h4>
                                                        <a href="#time" data-toggle="collapse" aria-expanded="false"
                                                           role="button" class="collapsed">設立時期</a>
                                                    </h4>
                                                </div>
                                                <div class="widget-boxed-body collapse" id="time" data-parent="#time">
                                                    <div class="side-list no-border">
                                                        <div class="single_filter_card">
                                                            <div class="card-body pt-0">
                                                                <div class="inner_widget_link">
                                                                    <ul class="no-ul-list filter-list">
                                                                        <li v-for="foundedYear in filterFoundedYear"
                                                                            :key="foundedYear">
                                                                            <input :id="'d' + foundedYear"
                                                                                   class="checkbox-custom"
                                                                                   :name="foundedYear" type="checkbox"
                                                                                   :value="foundedYear"
                                                                                   v-model="filter.foundedSections">
                                                                            <label :for="'d' + foundedYear"
                                                                                   class="checkbox-custom-label">{{foundedYear}}年代</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="dBefore2010"
                                                                                   class="checkbox-custom"
                                                                                   type="checkbox" value="1960"
                                                                                   v-model="filter.foundedSections">
                                                                            <label for="dBefore2010"
                                                                                   class="checkbox-custom-label">1970年代以前</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Capital Search -->
                                            <div class="single_search_boxed">
                                                <div class="widget-boxed-header">
                                                    <h4>
                                                        <a href="#capital" data-toggle="collapse" aria-expanded="false"
                                                           role="button" class="collapsed">資本金</a>
                                                    </h4>
                                                </div>
                                                <div class="widget-boxed-body collapse" id="capital"
                                                     data-parent="#capital">
                                                    <div class="side-list no-border">
                                                        <div class="single_filter_card">
                                                            <div class="card-body pt-0">
                                                                <div class="inner_widget_link">
                                                                    <ul class="no-ul-list filter-list">
                                                                        <li>
                                                                            <input id="e0" class="checkbox-custom"
                                                                                   type="checkbox" value="100000000"
                                                                                   v-model="filter.capitalSections">
                                                                            <label for="e0"
                                                                                   class="checkbox-custom-label">1億円以上</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="e1" class="checkbox-custom"
                                                                                   type="checkbox" value="50000000"
                                                                                   v-model="filter.capitalSections">
                                                                            <label for="e1"
                                                                                   class="checkbox-custom-label">5,000万円〜1億円未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="e2" class="checkbox-custom"
                                                                                   type="checkbox" value="30000000"
                                                                                   v-model="filter.capitalSections">
                                                                            <label for="e2"
                                                                                   class="checkbox-custom-label">3,000〜5,000万円未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="e3" class="checkbox-custom"
                                                                                   type="checkbox" value="10000000"
                                                                                   v-model="filter.capitalSections">
                                                                            <label for="e3"
                                                                                   class="checkbox-custom-label">1,000〜3,000万円未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="e4" class="checkbox-custom"
                                                                                   type="checkbox" value="5000000"
                                                                                   v-model="filter.capitalSections">
                                                                            <label for="e4"
                                                                                   class="checkbox-custom-label">500〜1,000万円未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="e5" class="checkbox-custom"
                                                                                   type="checkbox" value="1000000"
                                                                                   v-model="filter.capitalSections">
                                                                            <label for="e5"
                                                                                   class="checkbox-custom-label">100〜500万円未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="e6" class="checkbox-custom"
                                                                                   type="checkbox" value="0"
                                                                                   v-model="filter.capitalSections">
                                                                            <label for="e6"
                                                                                   class="checkbox-custom-label">100万円未満</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Workers Search -->
                                            <div class="single_search_boxed">
                                                <div class="widget-boxed-header">
                                                    <h4>
                                                        <a href="#workers" data-toggle="collapse" aria-expanded="false"
                                                           role="button" class="collapsed">従業員数</a>
                                                    </h4>

                                                </div>
                                                <div class="widget-boxed-body collapse" id="workers"
                                                     data-parent="#workers">
                                                    <div class="side-list no-border">
                                                        <div class="single_filter_card">
                                                            <div class="card-body pt-0">
                                                                <div class="inner_widget_link">
                                                                    <ul class="no-ul-list filter-list">
                                                                        <li>
                                                                            <input id="f0" class="checkbox-custom"
                                                                                   type="checkbox" value="10000"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f0"
                                                                                   class="checkbox-custom-label">10,000人以上</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f1" class="checkbox-custom"
                                                                                   type="checkbox" value="5000"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f1"
                                                                                   class="checkbox-custom-label">5,000〜10,000人未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f2" class="checkbox-custom"
                                                                                   type="checkbox" value="3000"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f2"
                                                                                   class="checkbox-custom-label">3,000〜5,000人未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f3" class="checkbox-custom"
                                                                                   type="checkbox" value="1000"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f3"
                                                                                   class="checkbox-custom-label">1,000〜3,00人未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f4" class="checkbox-custom"
                                                                                   type="checkbox" value="500"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f4"
                                                                                   class="checkbox-custom-label">500〜1,000人未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f5" class="checkbox-custom"
                                                                                   type="checkbox" value="100"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f5"
                                                                                   class="checkbox-custom-label">100〜500人未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f6" class="checkbox-custom"
                                                                                   type="checkbox" value="50"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f6"
                                                                                   class="checkbox-custom-label">50〜100人未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f7" class="checkbox-custom"
                                                                                   type="checkbox" value="30"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f7"
                                                                                   class="checkbox-custom-label">30〜50人未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f8" class="checkbox-custom"
                                                                                   type="checkbox" value="15"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f8"
                                                                                   class="checkbox-custom-label">15人〜30人未満</label>
                                                                        </li>
                                                                        <li>
                                                                            <input id="f9" class="checkbox-custom"
                                                                                   type="checkbox" value="0"
                                                                                   v-model="filter.employeeSection">
                                                                            <label for="f9"
                                                                                   class="checkbox-custom-label">15人未満</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Branch Search -->
                                            <div class="single_search_boxed">
                                                <div class="widget-boxed-header">
                                                    <h4>
                                                        <a href="#branch" data-toggle="collapse" aria-expanded="false"
                                                           role="button" class="collapsed">支店・支社有無</a>
                                                    </h4>
                                                </div>
                                                <div class="widget-boxed-body collapse" id="branch"
                                                     data-parent="#branch">
                                                    <div class="side-list no-border">
                                                        <div class="single_filter_card">
                                                            <div class="card-body pt-0">
                                                                <div class="inner_widget_link">
                                                                    <ul class="no-ul-list filter-list">
                                                                        <li>
                                                                            <input id="g0" class="checkbox-custom"
                                                                                   type="checkbox"
                                                                                   v-model="filter.isBranchOffice">
                                                                            <label for="g0"
                                                                                   class="checkbox-custom-label">あり</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- License Search -->
                                            <div class="single_search_boxed">
                                                <div class="widget-boxed-header">
                                                    <h4>
                                                        <a href="#license" data-toggle="collapse" aria-expanded="false"
                                                           role="button" class="collapsed">一般派遣免許</a>
                                                    </h4>

                                                </div>
                                                <div class="widget-boxed-body collapse" id="license"
                                                     data-parent="#license">
                                                    <div class="side-list no-border">
                                                        <div class="single_filter_card">
                                                            <div class="card-body pt-0">
                                                                <div class="inner_widget_link">
                                                                    <ul class="no-ul-list filter-list">
                                                                        <li>
                                                                            <input id="h0" class="checkbox-custom"
                                                                                   name="Have" type="checkbox"
                                                                                   v-model="filter.isDispatchLicense">
                                                                            <label for="h0"
                                                                                   class="checkbox-custom-label">あり</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- License Search -->
                                        <div class="single_search_boxed">
                                            <div class="widget-boxed-header">
                                                <h4>
                                                    <a href="#jobinfo" data-toggle="collapse" aria-expanded="false"
                                                       role="button" class="collapsed">求人情報</a>
                                                </h4>
                                            </div>
                                            <div class="widget-boxed-body collapse" id="jobinfo" data-parent="#jobinfo">
                                                <div class="side-list no-border">
                                                    <div class="single_filter_card">
                                                        <div class="card-body pt-0">
                                                            <div class="inner_widget_link">
                                                                <ul class="no-ul-list filter-list">
                                                                    <li>
                                                                        <input id="j0" class="checkbox-custom"
                                                                               name="Have" type="checkbox"
                                                                               v-model="filter.hasJobInfo">
                                                                        <label for="j0"
                                                                               class="checkbox-custom-label">あり</label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group form-button">
                                            <button type="submit" class="btn btn btn-theme-2 full-width form-button"
                                                    style="width: 100%; margin: 0;" @click="updateFilterList">絞り込み
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          <!-- Banner Area -->
                          <div class="companyListThumb" style="margin-top: 20px">
                            <router-link to="/companylist">
                              <img src="assets/img/CompanyListBanner.png" class="home-top-img" alt="企業リストページへのリンク画像" />
                            </router-link>
                          </div>
                          <div class="companyListThumb">
                            <a target="_blank" href="https://forms.gle/AQ4ULJHt99JVvCFT6">
                              <img src="assets/img/ArticleAds2.png" class="home-top-img" alt="記事内広告募集のリンク画像" />
                            </a>
                          </div>
                          <div class="companyListThumb">
                            <router-link to="/register">
                              <img src="assets/img/RecommendBanner.png" class="home-top-img" alt="企業アカウントの解説を進める画像" />
                            </router-link>
                          </div>
                          <!--<div class="companyListThumb">
                            <a target="_blank" href="https://forms.gle/xCkGCffvauSSQJEP7">
                              <img src="assets/img/RequestBanner.png" class="home-top-img" alt="機能改善募集のリンク画像" />
                            </a>
                          </div>-->
                          <div class="companyListThumb">
                            <a target="_blank" href="https://forms.gle/AQ4ULJHt99JVvCFT6">
                              <img src="assets/img/AdsBanner.png" class="home-top-img" alt="広告掲載募集のリンク画像" />
                            </a>
                          </div>
                        </div>
                        <!-- Item Wrap Start -->
                        <div class="col-lg-8 col-md-12 col-sm-12">
                            <div id="wrap-start" class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <!-- Filter Search -->
                                    <div class="_filt_tag786">
                                        <div class="_tag782">
                                            <div class="_tag780_2">{{this.currentStartIndex +
                                                1}}-{{this.currentStartIndex + this.currentPageList.length}}
                                            </div>
                                            <div class="_tag780">/{{this.filteredList.length}} 社表示中</div>
                                        </div>
                                        <div class="_tag785">
                                            <div class="_g78juy">
                                                <select v-model="selectSortType" class="form-control">
                                                    <option v-for="sortType of sortTypes" :key="sortType"
                                                            :value="sortType">{{getSortText(sortType)}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="data of currentPageList" :key="data.No" class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <!-- Single Item -->
                                    <div class="_large_jb_list72 shadow_0">
                                        <div class="_large_jb_header">
                                            <div class="_list_110">
                                                <div class="_list_110_caption">
                                                    <!--書き換えが必要-->
                                                    <div class="company-top-wrap">
                                                        <div v-if="data.recruitmentStatus !== ''" class="company-top">
                                                            <div v-if="data.recruitmentStatus === 1"
                                                                 class="jb_types parttime position_setting all-partner">
                                                                パートナー企業積極募集中！
                                                            </div>
                                                            <div v-if="data.recruitmentStatus === 2"
                                                                 class="jb_types parttime position_setting project-partner">
                                                                案件に強いパートナー企業募集中！
                                                            </div>
                                                            <div v-if="data.recruitmentStatus === 3"
                                                                 class="jb_types parttime position_setting personnel-partner">
                                                                要員に強いパートナー企業募集中！
                                                            </div>
                                                            <div v-if="data.recruitmentStatus === 4"
                                                                 class="jb_types parttime position_setting suspension">パートナー企業募集停止中
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="company-name"><h4 class="_jb_title"><a :href="data.url"
                                                                                                       target="_blank"
                                                                                                       rel="noopener noreferrer">{{data.name}} </a>
                                                    </h4></div>
                                                    <div class="_emp_jb">所在地：{{data.address}}</div>
                                                    <div class="_large_jb_body_list_info">
                                                        <ul>
                                                            <li v-if="data.foundedYear" class="letter-space">
                                                                設立：{{data.foundedYear}}年
                                                            </li>
                                                            <li v-if="data.capital" class="letter-space">資本金：
                                                                {{Number(data.capital).toLocaleString()}}円
                                                            </li>
                                                            <li v-if="data.employeeNum" class="letter-space">従業員数：
                                                                {{data.employeeNum}}人
                                                            </li>
                                                            <li v-if="data.phoneNumber" class="letter-space">電話番号：
                                                                {{data.phoneNumber}}
                                                            </li>
                                                            <li v-if="data.isBranchOffice" class="letter-space">
                                                                支店・支社有無：あり
                                                            </li>
                                                            <li v-if="data.isDispatchLicense" class="letter-space">
                                                                一般派遣免許：あり
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="data.companyFeatures.length > 0" class="_large_jb_body2">
                                            <div class="_large_jb_body_list_explain">
                                                <div class="jb_req_skill">
                                                    <ul>
                                                        <li v-for="featureType of getFeatureTypes(data.companyFeatures)"
                                                            :key="featureType">
                                                            <span>{{getFeatureText(featureType)}}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="data.jobInfoList && data.jobInfoList.length > 0">
                                            <div class="form-button-recruit-frame">
                                                <div v-for="jobInfo of data.jobInfoList" :key="jobInfo.id">
                                                    <router-link class="form-button-recruit" :to="{ name: 'JobInfoDetail', query: { id: jobInfo.id }}">{{getJobCategoryText(jobInfo.jobCategory)}} {{jobInfo.title}} 求人</router-link>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <!-- Pagenation -->
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <paginate ref="paginate" v-model="currentPage"
                                              :page-count="pageCount"
                                              :page-range="5"
                                              :click-handler="onClickPage"
                                              :prev-text="''"
                                              :next-text="''"
                                              :page-class="'page-item'"
                                              :page-link-class="'page-link'"
                                              :prev-link-class="'ti-arrow-left'"
                                              :next-link-class="'ti-arrow-right'"
                                              :container-class="'pagination'">
                                    </paginate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ Main Section End ================================== -->

            <!-- ============================ Call To Action Start ================================== -->
            <section class="call-to-act">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <div class="clt-caption">
                                <h3 class="text-light ">ご利用の際の注意事項</h3>
                                <span class="text-light precautionary-text">・掲載している情報が正確ではない可能性があります。</span><br>
                                <span class="text-light precautionary-text">・現在、存在していない企業を掲載している可能性があります。</span><br>
                                <span class="text-light precautionary-text">・情報の更新・削除はお問い合わせから5営業日以内に反映致します。</span><br>
                                <span class="text-light precautionary-text">・本リストを使用しての問題やトラブルの責任については一切負いません。</span><br>
                                <span class="text-light precautionary-text">・すべてのSES企業様・フリーランスエージェント様を掲載できておりません。</span><br>
                                <span class="text-light precautionary-text">・現在、SES事業・フリーランスエージェント事業を行っていない企業を掲載している可能性があります。</span>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ Call To Action End ================================== -->
            <Footer></Footer>
        </div>

        <!-- ============================================================== -->
        <!-- End Wrapper -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
    import Paginate from 'vuejs-paginate'
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    // import {decrypt} from "@/common/utils";
    import MixIn from "@/common/mixin";
    import moment from "moment";
    import ApiMixIn from "@/common/api_mixin";
    import {getJobCategoryText} from "@/common/enum";

    // const keyBytes = [
    //     new Uint8Array([176, 102, 143, 72]),
    //     new Uint8Array([177, 101, 147, 100, 205, 80]),
    //     new Uint8Array([133, 65, 144, 98, 134, 115])
    // ];

    const SortTypes = {
        Recommended: 0,
        CompanyName: 1,
        OldEstablishmentYear: 2,
        NewEstablishmentYear: 3,
        DescCapital: 4,
        AscCapital: 5,
        DescEmployees: 6,
        AscEmployees: 7
    }


    const displayPageNum = 50;
    const sortText = [
        "おすすめ順",
        "会社名順",
        "設立年が古い順",
        "設立年が新しい順",
        "資本金が多い順",
        "資本金が少ない順",
        "従業員数が多い順",
        "従業員数が少ない順"
    ];
    const featureText = [
        "エンド直案件あり",
        "元請け直案件あり",
        "ロースキル案件あり",
        "自社内・グループ会社内案件あり",
        "社員・プロパー営業あり",
        "直フリーランスの扱いあり",
        "業務系に強い",
        "Web系に強い",
        "ゲーム系に強い"
    ]
    const defaultFilter = {
        name: "",
        recruitmentStatus: [],
        features: [],
        area: [],
        subArea: [],
        foundedSections: [],
        capitalSections: [],
        employeeSection: [],
        isBranchOffice: false,
        isDispatchLicense: false,
        hasJobInfo: false,
    }
    export default {
        name: 'CompanyList',
        components: {Header, Footer, Paginate},
        mixins: [MixIn, ApiMixIn],
        data: function () {
            let currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
            if (currentPage <= 0) {
                currentPage = 1;
            }
            return {
                filterFoundedYear: [...Array(Math.ceil((new Date().getFullYear() - 1970) * 0.1))].map((_, i) => (i * 10) + 1970).reverse(),
                filter: {...defaultFilter},
                sesList: [],
                filteredList: [],
                currentPageList: [],
                currentPage: currentPage,
                initPage: currentPage,
                sortTypes: Object.values(SortTypes),
                selectSortType: SortTypes.Recommended,
                isPrivacyAccept: false,
                updateDate: moment().format("YYYY年M月D日"),
                selectedJobInfo: null
            }
        },
        created: function () {
            // this.loadJSON();
            this.loadCompanyList();
        },
        computed: {
            pageCount: {
                get() {
                    return Math.ceil(this.filteredList.length / displayPageNum);
                },
            },
            currentStartIndex: {
                get() {
                    return (this.currentPage - 1) * displayPageNum;
                }
            },
            currentEndIndex: {
                get() {
                    return this.currentStartIndex + displayPageNum;
                }
            }
        },
        watch: {
            selectSortType: function () {
                this.currentPage = 1;
                this.updateSortList();
            },
            '$route.params': function () {
                this.resetParams();
            },
            '$route.query': function () {
                this.resetParams();
            }
        },
        methods: {
            // loadJSON: async function () {
            //     try {
            //         const response = await fetch('assets/json/SESLIST.json');
            //         const text = await response.text();
            //         // this.sesList = JSON.parse(decrypt(text, keyBytes));
            //         const a = JSON.parse(decrypt(text, keyBytes));
            //         console.log(a);
            //         // this.updateFilterList();
            //         // this.isLoading = false;
            //
            //     } catch (e) {
            //         console.error(e);
            //     }
            // },
            loadCompanyList: async function () {
                const response = await this.getApi("company/getList");
                if (response) {
                    this.sesList = response.data;
                    this.updateFilterList();
                }
            },
            updateFilterList: function () {
                this.selectSortType = SortTypes.Recommended;
                this.filteredList = this.sesList.filter(this.filterData);
                this.updateSortList();
                this.scrollTop();
            },
            updateSortList: function () {
                let sortFunc = this.sortRecommended;
                switch (this.selectSortType) {
                    case SortTypes.CompanyName:
                        sortFunc = this.sortCompanyName;
                        break;
                    case SortTypes.OldEstablishmentYear:
                        sortFunc = this.sortEstablishmentYear.bind(null, false);
                        break;
                    case SortTypes.NewEstablishmentYear:
                        sortFunc = this.sortEstablishmentYear.bind(null, true);
                        break;
                    case SortTypes.DescCapital:
                        sortFunc = this.sortCapital.bind(null, true);
                        break;
                    case SortTypes.AscCapital:
                        sortFunc = this.sortCapital.bind(null, false);
                        break;
                    case SortTypes.DescEmployees:
                        sortFunc = this.sortEmployees.bind(null, true);
                        break;
                    case SortTypes.AscEmployees:
                        sortFunc = this.sortEmployees.bind(null, false);
                        break;
                }
                this.filteredList.sort(sortFunc);
                this.updatePageList()
            },
            updatePageList: function () {
                this.currentPageList = this.filteredList.slice(this.currentStartIndex, this.currentEndIndex);
            },
            resetFilter: function () {
                this.filter = {...defaultFilter};
            },
            filterData: function (data) {
                if (!data || !data.name) {
                    return false;
                }
                if (this.filter.name) {
                    const index = data.name.indexOf(this.filter.name);
                    if (index === -1) {
                        return false;
                    }
                }
                if (this.filter.recruitmentStatus.length > 0) {
                    if (!this.filter.recruitmentStatus.some(status => Number(status) === Number(data.recruitmentStatus))) {
                        return false;
                    }
                }
                if (this.filter.features.length > 0) {
                    const featureTypes = this.getFeatureTypes(data.companyFeatures);
                    if (!this.filter.features.some(feature => featureTypes.indexOf(Number(feature)) !== -1)) {
                        return false;
                    }
                }
                if (this.filter.area.length > 0) {
                    if (!this.filter.area.some(area => Number(area) === Number(data.area))) {
                        return false;
                    }
                }
                if (this.filter.subArea.length > 0) {
                    if (!this.filter.subArea.some(subArea => Number(subArea) === Number(data.subArea))) {
                        return false;
                    }
                }

                if (this.filter.foundedSections.length > 0) {
                    const isSomeSection = this.filter.foundedSections.some(founded => {
                        if (!data.foundedYear) {
                            return false;
                        }
                        const sectionYear = Number(founded);
                        const foundedYear = Number(data.foundedYear);
                        if (sectionYear === 1960) {
                            return foundedYear < sectionYear + 10;
                        }
                        return foundedYear >= sectionYear && foundedYear < sectionYear + 10;
                    });
                    if (!isSomeSection) {
                        return false;
                    }
                }

                if (this.filter.capitalSections.length > 0) {
                    if (!data.capital) {
                        return false;
                    }
                    const isSomeSection = this.filter.capitalSections.some(capital => {
                        const min = Number(capital);
                        let max = 0;
                        switch (min) {
                            case 0:
                                max = 1000000;
                                break;
                            case 1000000:
                                max = 5000000;
                                break;
                            case 5000000:
                                max = 10000000;
                                break;
                            case 10000000:
                                max = 30000000;
                                break;
                            case 30000000:
                                max = 50000000;
                                break;
                            case 50000000:
                                max = 100000000;
                                break;
                            case 100000000:
                                max = Number.MAX_SAFE_INTEGER;
                                break;
                        }
                        const targetCapital = Number(data.capital);
                        return min <= targetCapital && targetCapital < max;
                    });
                    if (!isSomeSection) {
                        return false;
                    }
                }

                if (this.filter.employeeSection.length > 0) {
                    if (!data.employeeNum) {
                        return false;
                    }
                    const isSomeSection = this.filter.employeeSection.some(employee => {
                        const min = Number(employee);
                        let max = 0;
                        switch (min) {
                            case 0:
                                max = 15;
                                break;
                            case 15:
                                max = 30;
                                break;
                            case 30:
                                max = 50;
                                break;
                            case 50:
                                max = 100;
                                break;
                            case 100:
                                max = 500;
                                break;
                            case 500:
                                max = 1000;
                                break;
                            case 1000:
                                max = 3000;
                                break;
                            case 3000:
                                max = 5000;
                                break;
                            case 5000:
                                max = 10000;
                                break;
                            case 10000:
                                max = Number.MAX_SAFE_INTEGER;
                                break;
                        }
                        const targetEmployeeNum = Number(data.employeeNum);
                        return min <= targetEmployeeNum && targetEmployeeNum < max;
                    });
                    if (!isSomeSection) {
                        return false;
                    }
                }
                if (this.filter.isBranchOffice) {
                    return data.isBranchOffice;
                }
                if (this.filter.isDispatchLicense) {
                    return data.isDispatchLicense;
                }
                if (this.filter.hasJobInfo) {
                    return data.jobInfoList && data.jobInfoList.length > 0;
                }
                return true;
            },
            onClickPage: function (page) {
                this.$router.push({path: '/companylist', query: {page: page.toString()}});
            },
            getFeatureTypes: function (companyFeatures) {
                if (!companyFeatures) {
                    return []
                }
                return companyFeatures.map(companyFeature => Number(companyFeature.feature));
            },
            getFeatureText: function (featureType) {
                return featureText[featureType];
            },
            getSortText: function (sortType) {
                return sortText[sortType];
            },
            sortRecommended: function (a, b) {
                const aPriority = this.getRecommendPriority(a);
                const bPriority = this.getRecommendPriority(b);
                if (aPriority === bPriority) {
                    return Number(b.id) - Number(a.id);
                }
                return bPriority - aPriority;
            },
            sortCompanyName: function (a, b) {
                return a.name.localeCompare(b.name);
            },
            sortEstablishmentYear: function (isDest, a, b) {
                if (a.foundedYear === b.foundedYear) {
                    return 0;
                }
                if (!a.foundedYear) {
                    return 1;
                }
                if (!b.foundedYear) {
                    return -1;
                }
                if (isDest) {
                    return Number(b.foundedYear) - Number(a.foundedYear);
                }
                return Number(a.foundedYear) - Number(b.foundedYear);
            },
            sortCapital: function (isDest, a, b) {
                if (a.capital === b.capital) {
                    return 0;
                }
                if (!a.capital) {
                    return 1;
                }
                if (!b.capital) {
                    return -1;
                }
                if (isDest) {
                    return Number(b.capital) - Number(a.capital);
                }
                return Number(a.capital) - Number(b.capital);
            },
            sortEmployees: function (isDest, a, b) {
                if (a.employeeNum === b.employeeNum) {
                    return 0;
                }
                if (!a.employeeNum) {
                    return 1;
                }
                if (!b.employeeNum) {
                    return -1;
                }
                if (isDest) {
                    return Number(b.employeeNum) - Number(a.employeeNum);
                }
                return Number(a.employeeNum) - Number(b.employeeNum);
            },
            getRecommendPriority: function (data) {
                if (data.recruitmentStatus) {
                    let priority = 0;
                    let recruitmentStatus = Number(data.recruitmentStatus);
                    if (recruitmentStatus !== 4) {
                        priority += 100;

                        if (data.companyFeatures.length > 0) {
                            priority += 10
                        }
                        priority += 4 - Number(data.recruitmentStatus)
                    } else {
                        if (data.companyFeatures.length > 0) {
                            priority++;
                        }
                    }
                    return priority;
                } else if (data.companyFeatures.length > 0) {
                    return -1;
                }
                return -2;
            },
            resetParams() {
                this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
                if (this.currentPage <= 0) {
                    this.currentPage = 1;
                }
                this.updatePageList();
            },
            scrollTop() {
                const wrapStart = document.getElementById("wrap-start");
                const header = document.getElementById("header");
                window.scrollTo(0, (wrapStart.getBoundingClientRect().y + window.pageYOffset) - header.offsetHeight - 20);
            },
            getJobCategoryText (jobCategoryType) {
                return getJobCategoryText(jobCategoryType);
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .vload-leave-active {
        transition: opacity 350ms;
    }

    .vload-leave-to {
        opacity: 0;
    }
</style>
